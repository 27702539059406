<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b card-nobody card-sticky">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche lead #{{lead.id}}</h3>
        </div>
        <div class="card-title div-center">
          <b-button block :variant="LeadStatus.color" size="lg" style="font-size:1.20em">{{LeadStatus.titre}}</b-button>
        </div>
        <div class="card-toolbar div-center">
          <b-dropdown v-b-tooltip.hover title="Utilisateur" v-if="$store.back.getUser().role_id === 1" class="mr-1" size="sm" variant="primary" html='<i class="fas fa-user"></i>'>
            <b-dropdown-item v-for="u in params.MyUsers.users" :key="u.id" @click="lead.user_id = u.id" :active="u.id === lead.user_id">{{u.prenom}} {{u.nom[0]}}</b-dropdown-item>
          </b-dropdown>
          <a v-b-tooltip.hover title="sauvegarder" class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save" v-if="save_loader === false"></i>
            <i class="icon-md fas fa-circle-notch fa-spin" v-else></i>
          </a>
        </div>
      </div>
    </div>
    <b-tabs pills card>
      <b-tab title="Information client" active class="tab-card-body">
        <form ref="form">
          <div class="card-deck">
            <div class="card card-custom gutter-b card-nobody">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Information de l'assuré</h3>
                </div>
              </div>
              <div class="card-body">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nom:">
                      <b-form-input v-model="lead.nom" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Prenom:">
                      <b-form-input v-model="lead.prenom"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Date de naissance:">
                      <b-form-input type="date" v-model="lead.date_naissance" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Mobile:">
                      <b-form-input v-model="lead.mobile"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Telephone:">
                      <b-form-input v-model="lead.telephone"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Email:">
                      <b-form-input v-model="lead.email"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Code postal:">
                      <b-form-input v-model="lead.cp" pattern="[0-9]{5}" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Date d'effet:">
                      <b-form-input type="date" v-model="lead.date_effet" :min="min_date" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Regime:">
                      <select class="form-control" v-model="lead.regime_id" required>
                        <option v-for="(r,i) in params.Regime" :value="r.id" :key="i">{{r.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Mutuelle:">
                      <treeselect v-model="lead.mutuelle_id" :multiple="true" :options="params.Mutuelle"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="card card-custom gutter-b card-nobody">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">D'autres personnes ?</h3>
                </div>
              </div>
              <div class="card-body">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Avec vous un conjoint:">
                      <b-form-checkbox name="checkbox-size" size="lg" v-model="question_conjoint">Oui</b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="question_conjoint">
                    <b-form-group label="Regime:">
                      <select class="form-control" v-model.number="lead.conjoint.regime_id" required>
                        <option v-for="(r,i) in params.Regime" :value="r.id" :key="i">{{r.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="question_conjoint">
                    <b-form-group label="Date de naissance:">
                      <b-form-input type="date" v-model="lead.conjoint.date_naissance" required></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-button block variant="success" @click="addEnfant()">Ajouter un enfant</b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-3" v-for="(e,i) in lead.enfants" :key="i">
                  <b-col md="5">
                    <b-form-group :label="'Date de naissance enfant ' + (i+1)">
                      <b-form-input type="date" v-model="e.date_naissance" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Regime:">
                      <select class="form-control" v-model="e.regime_id" required>
                        <option v-for="(r,ri) in params.Regime" :value="r.id" :key="ri">{{r.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Action:">
                      <b-button class="btn-icon" variant="danger" size="sm" @click="removeEnfant(i)"><i class="fas fa-trash"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </form>
      </b-tab>
      <b-tab title="Tarifs"  class="tab-card-body" v-if="create === false">
        <div class="card gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Les offres ({{datatable.totalRow}})</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <b-col md="4">
                <b-form-group style="padding: 10px;" :label="'Tarif ('+filters.min_tarif+' - '+filters.max_tarif+')'" v-if="datatable.meta.min_tarif != 0">
                  <veeno  connect @slide="setTarif" :step="5" :handles="[filters.min_tarif, filters.max_tarif]" :range ="{'min':datatable.meta.min_tarif,'max':datatable.meta.max_tarif}"/>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Mutuelle:">
                  <treeselect v-model="filters.mutuelle_id" :multiple="true" :options="quoteMutuelle" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Titre:">
                  <treeselect v-model="filters.titre" :options="datatable.meta.titre" placeholder="Formule 1" :multiple="true" style="z-index:999"/>
                </b-form-group>
              </b-col>
            </div>
            <div class="row">
              <div class="offset-md-5 col-md-4">
                <button type="button" class="btn btn-primary mr-1 mb-1 waves-effect waves-light" @click="datatableInit()">{{$store.back.getText('recherchez')}}</button>
              </div>
            </div>
            <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
              <template #cell(mutuelle_id)="data">
                {{$store.back.getParamTitre(params.Mutuelle,data.value)}}
              </template>
              <template #cell(titre)="data">
                <span v-b-tooltip.hover :title="data.item.description">{{data.value}}</span>
              </template>
            </b-table>
            <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="card card-custom gutter-b mt-3">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Commentaire</h3>
        </div>
      </div>
      <div class="card-body">
        <b-form-group :label="this.$store.back.getText('commentaire')+':'" >
          <b-form-textarea v-model="lead.commentaire" rows="4" readonly></b-form-textarea>
        </b-form-group>
        <b-form-group :label="this.$store.back.getText('ajouter')+':'">
          <b-input-group>
            <b-form-input v-model="commentaireTmp" type="text" placeholder="Entre votre commentaire"></b-form-input>
            <template #append>
              <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_commentaire')" variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Treeselect from '@riophae/vue-treeselect';
import veeno from 'veeno';
import 'nouislider/distribute/nouislider.min.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  data(){
    return {
      ready:false,
      create:true,
      save_loader:false,
      commentaireTmp:null,
      lead: this.$store.back.getSchema('Lead'),
      params:Object,
      question_conjoint:false,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable:false,
        },
        {
          key: 'mutuelle_id',
          label: 'Mutuelle',
          sortable:false,
        },
        {
          key: 'titre',
          label: 'Titre',
          sortable:false,
        },
        {
          key: 'tarif',
          label: 'Tarif',
          sortable:false,
        }
      ],
      datatable:{
        currentPage: 1,
        limitPage: 20,
        totalRow: 0,
        data: [],
        meta:{
          min_tarif:0,
          max_tarif:0,
          titre:[]
        }
      },
      sort:{
        key: 'tarif',
        value: 'ASC'
      },
      filters:{
        "lead_id":null,
        "min_tarif":0,
        "max_tarif":1000,
        "mutuelle_id":[],
        "titre":[]
      }
    };
  },
  watch: {
    'question_conjoint'(){
      if(this.question_conjoint === false){
        this.lead.conjoint.date_naissance = null;
        this.lead.conjoint.regime_id = null;
      }
    },
    'lead.mutuelle_id'(){
      if(this.create === false){
        this.filters.mutuelle_id = JSON.parse(JSON.stringify(this.lead.mutuelle_id));
      }
    }
  },
  components: {
    Treeselect,veeno
  },
  methods: {
    addCommentaire(){
      if(this.lead.commentaire === null){
        this.lead.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.lead.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    addEnfant(){
      this.lead.enfants.push({date_naissance:"",regime_id:null});
    },
    removeEnfant(i){
      this.lead.enfants.splice(i,1);
    },
    save() {
      if(this.checkForm() === false){
        this.$bvToast.toast(`Merci de renseigner les champs en rouge !`, {
          title: 'Oups !',
          variant: 'danger',
          solid: true
        });
        return false;
      }
      this.save_loader = true
      this.$store.back.ajax('/lead/create', this.lead, (res) => {
        if(res.status){
          if(this.create === true){
            this.$router.push({path:'/lead/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.client = res.data;
            this.$bvToast.toast(`Lead enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
            this.datatableInit(true);
          }
          this.save_loader = false
        }
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    datatableInit(){
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/lead/quote/liste', params,(res) => {
        this.filters.min_tarif = res.meta.min_tarif;
        this.filters.max_tarif = res.meta.max_tarif;
        this.datatable = res;
      });
    },
    setTarif(e){
      this.filters.min_tarif = parseInt(e.values[0])
      this.filters.max_tarif = parseInt(e.values[1])
    },
    checkForm() {
      var form = this.$refs.form;
      var fields = form.querySelectorAll("[required='required']");
      var valide = true;
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          valide = false;
        }else{
          item.classList.remove("need-validation");
        };
      });
      if(this.lead.mutuelle_id.length === 0){
        document.getElementsByClassName("vue-treeselect__control")[0].classList.add("need-validation")
        valide = false;
      }else{
        document.getElementsByClassName("vue-treeselect__control")[0].classList.remove("need-validation")
      }
      return valide;
    },
  },
  computed: {
    LeadStatus(){
      var check = this.params.LeadStatus.find(s => s.id == this.lead.status_id);
      if(check === undefined){
        check = {
          id:1,
          titre:{
            fr:"Disponible"
          },
          color:"primary"
        }
      }
      return check;
    },
    quoteMutuelle(){
      var res = [];
      this.lead.mutuelle_id.forEach((item) => {
        var check = this.params.Mutuelle.find(m => m.id === item);
        res.push(check)
      });
      return res;
    }
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/lead/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.filters.titre = res.data.filters.titre;
          this.lead = res.data;
          this.create = false;
          if(this.lead.conjoint.regime_id != null){
            this.question_conjoint = true;
          }
          this.filters.lead_id = this.lead.id;
          this.filters.mutuelle_id = JSON.parse(JSON.stringify(this.lead.mutuelle_id));
          this.datatableInit(true);
        }
      });
    }
    this.$store.back.ajax('/magic/all', null, (res) => {
      if(res.status === true){
        res.data.Mutuelle.forEach((item) => {
          item.label = item.titre
        });
        this.params = res.data
        this.ready = true;
      }
    });
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    this.min_date = tomorrow.toISOString().split('T')[0]
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
<style media="screen">
.need-validation{
  border: solid 2px red;
}
</style>
